#shadowButton {
  @media(min-width: "3840px"){
    font-size: 2em !important;
  }
  position: relative;
  z-index: 1;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--backgroundColor);
    transition: all 0.6s;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
}
