.form {
  .form__inner {
    .form__direction {
      display: flex;
      flex-direction: column;
    }
  }
}
.CheckBlock {
  .checkBlock__direction {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
@media (max-width: "576px") {
  .checkBlock__direction {
    flex-direction: column;
  }
}