.section {
  .section__inner {
    margin-top: 20px;
    .section__direction {
        display: flex;
        .section__display{
          width: 60%;
          display: flex;
          flex-direction: column;

        }
    }
  }
}
@media (max-width: "576px") {
  .section__direction{
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: "576px") {
  .section__direction{
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
}