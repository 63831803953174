.suggestion {
  display: grid;
  width: 100%;
  padding-top: 15px;
  font-size: 1em;
  @media (max-width: "768px") {
    place-items: center;
  }
  @media(min-width: "2048px"){
    font-size: 1.5em !important;
  }
  @media(min-width: "3840px"){
    font-size: 3em !important;
  }
  .suggestion__inner {
    margin: 0 auto;
    width: fit-content;
    .suggestion__direction {
      display: flex;
      flex-direction: column;
      align-items: center;
      .suggestion__text {
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1rem;
        @media(min-width: "2048px"){
          font-size: inherit !important;
        }
      }
      .suggestion__btn {
        @media(min-width: "3840px"){
          font-size: inherit !important;
        }
        &:hover{
          color: black;
        }
        &:hover span{
          transform: translateY(0) scale(2);
        }
        cursor: pointer;
        --c: goldenrod;
        color: var(--c);
        font-size: 16px;
        border: 4px solid var(--c);
        border-radius: 0.5em;
        width: 12em;
        height: 3em;
        text-transform: uppercase;
        font-weight: bold;
        font-family: sans-serif;
        letter-spacing: 0.1em;
        text-align: center;
        line-height: 3em;
        position: relative;
        overflow: hidden;
        z-index: 1;
        transition: 0.5s;
        margin: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span{
          position: absolute;
          width: 25%;
          height: 100%;
          background-color: var(--c);
          transform: translateY(150%);
          border-radius: 50%;
          left: calc((var(--n) - 1) * 25%);
          transition: 0.5s;
          transition-delay: calc((var(--n) - 1) * 0.1s);
          z-index: -1;
          &:nth-child(1){
            --n: 1;
          }
          &:nth-child(2){
            --n: 2;
          }
          &:nth-child(3){
            --n: 3;
          }
          &:nth-child(4){
            --n: 4;
          }
        }
      }
    }
  }
}
.suggestion__imgs{
  @media(min-width: "3840px"){
    scale: 2;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  img{
    margin-left: 5px;
    margin-right: 5px;

    width: 30px;
    aspect-ratio: 4/3;
    cursor: pointer;
    transition: all .2s;
    &:hover{
      transform: scale(1.2);
    }
  }
}
.email{
  height: 25px;
  min-width: 100%;
  font-family: 'Courier New', Courier, monospace;
  border: 2px solid black;
  outline: none;
  @media(min-width: "3840px"){
    font-size: inherit !important;
  }
}
#privacy{
  width: fit-content;
}