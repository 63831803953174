.header {
    width: 100%;
  .header__inner {
    padding-top: 20px;
    .header__direction {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      .logo{
        cursor: pointer;
        a{
          svg{
            @media(min-width: "3840px"){
              height: 100px !important;
              width: 500px !important;
            }
          }
        }
      }
      .header__btn {
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 18px;
        @media(min-width: "2048px"){
          font-size: 2em !important;
        }
        @media(min-width: "3840px"){
          font-size: 3em !important;
        }
        cursor: pointer;
      }
    }
  }
}
.container {
  height: 100vh;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
#privacy{
  grid-row: none;
  text-decoration: none;
  font-family: 'Courier New', Courier, monospace;
  color: black;
  &:hover{
    text-decoration: underline;
  }
}