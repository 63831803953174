.fullContent {
  z-index: 100;
  width: 90vw;
  height: 100vh;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: row;
}
