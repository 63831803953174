.Authorization {
  height: 100vh;
  overflow: hidden;
  .authorization__inner {
    width: 50%;
    margin: 0 auto;
    margin-top: 150px;
    .authorization__direction {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: "Courier New", Courier, monospace;
      .authorization__welcome {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .authorization__label {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
      }
      .inputBlock {
        align-self: flex-start;
        width: 100%;
        .inputBlock__inner {
          margin-top: 20px;
          .inputBlock__direction {
            display: flex;
            flex-direction: column;
            align-items: center;
            .inputBlock__label {
              font-family: "Franklin Gothic Medium", "Arial Narrow", Arial,
                sans-serif;
              align-self: flex-start;
              margin-bottom: 2px;
            }
            .inputBlock__input {
              margin-bottom: 20px;
              width: 100%;
              height: 60px;
              outline: none;
              border-radius: 5px;
              border: 5px solid black;
              text-indent: 5px;
              font-size: 18px;
            }
            .inputBlock__button {
              border: none;
              cursor: pointer;
              border-radius: 5px;
              background-color: black;
              color: white;
              font-size: 18px;
              width: 75px;
              height: 35px;
              transition: all 0.2s;
              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }
  }
}
.suggest {
  text-align: center;
  margin-top: 20px;
  @media(min-width: "2048px"){
    font-size: 2em !important;
  }
  a {
    padding-top: 15px;
    display: block;
    cursor: pointer;
    color: black;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    width: 60%;
    font-style: italic;
    &::after {
      left: 0;
      top: 0;
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-bottom: 2px solid black;
      transition: all 0.2s;
      transform: translateX(150%);
    }
    &:hover {
      &::after {
        transform: translateX(0%);
      }
    }
  }
}
