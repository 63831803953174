.Input {
  width: 100%;
  .input__inner {
    width: inherit;
    .input__direction {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: inherit;
      .input__checkbox {
      }
      .input__block {
        display: flex;
        flex-direction: row;
        width: 100%;
        input{
          @media(min-width: "3840px"){
            height: 50px !important;
            font-size: 2em;
          }
        }
        .input__text {
          width: 100%;
          outline: none;
          border: 4px solid black;
          min-height: 35px;
        }
      }
    }
  }
}
