.buttons {
  z-index: 99;
  position: absolute;

  .buttons__imagesScroll {
    margin-right: 15px;
    cursor: pointer;
    width: 50px;
    height: 50px;
  }
}

