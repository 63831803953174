.error {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  .error__inner {
    background-color: white;
    width: 90%;
    max-width: fit-content;
    height: 40%;
    border: 5px solid black;
    border-radius: 5px;
    margin: 50px auto;
    @media (min-width: "576px") {
      height: 40%;
    }
    @media (max-width: "576px") {
      height: 90%;
    }
    .error__direction {
      width: 90%;
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: "576px") {
        justify-content: space-between;
        height: 90%;
      }
      .error__error {
        text-align: center;
        font-family: 'Courier New', Courier, monospace;
        font-weight: bold;
        font-size: 24px;
        @media(min-width: "3840px"){
          font-size: 3em !important;
        }
        @media (max-width: "576px") {
          margin-top: 20%;
        }
      }
      .error__btn {
        cursor: pointer;
        border-radius: 5px;
        border: none;
        background-color: black;
        color: white;
        padding: 15px;
        margin-top: 50px;
        @media(min-width: "3840px"){
          margin-top: 10% !important;
        }
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
}
