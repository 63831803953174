.options {
  .options__inner {
    .options__direction {
        display: flex;
        flex-direction: column;
    }
  }
}
.options__button{
  width: 25px;
  height: 25px;
}
@media (max-width: "576px") {
  .options__inner {
    margin-bottom: 40px;
  }

}