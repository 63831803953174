.notification {
  width: 100%;
  height: 100%;
    .notification__buttonBlock {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      .notification__btn {
        cursor: pointer;
        margin: 0 15px;
        width: 35%;
        min-height: 25%;
        background-color: white;
        border-radius: 10px;
        font-size: 18px;
        border: 4px solid black;
        font-weight: bold;
        font-family: "Courier New", Courier, monospace;
        text-transform: capitalize;
      }
    }
}
