.input {
  width: 100%;
  .input__inner {
    margin-top: 20px;
    .input__direction {
      display: flex;
      flex-direction: row;
      .input_asinput {
        // hack))
        background-color: white;
        padding-left: 5px;
        border-top: 4px solid black;
        border-bottom: 4px solid black;
      }
      @media (max-width: "576px") {
        .input_asinput {
          display: none !important;
        }
      }
      .input__button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.8rem;
        @media(min-width: "3840px"){
          height: 6em !important;
        }
        img{
          @media(min-width: "3840px"){
            scale: 2;
          }
        }
        min-width: 15%;
        width: 20%;
        background-color: black;
      }
      .input__input {
        color: black;
        font-size: 1.5rem;
        text-indent: 5px;
        outline-style: none;
        border-top: 4px solid black;
        border-bottom: 4px solid black;
        border-right: 4px solid black;
        border-left: 0;

        height: 3.8rem;
        @media(min-width: "3840px"){
          height: 6rem !important;
          font-size: 2.5rem;
        }
        width: 100%;
      }
    }
  }
}
@media (max-width: "576px") {
  .input__button {
    min-width: 30% !important;
    width: 100%;
  }
}
.input_asinput {
  display: flex;
  align-items: center;
  height: 3.8rem;
  @media(min-width: "3840px"){
    height: 6em !important;
  }
  .input_method {
    outline: none;
    cursor: pointer;
    font-size: 1.5rem;
    @media(min-width: "3840px"){
      font-size: 2.5em !important;
    }
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    padding: 5px;
    border: 4px dashed gray;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
  }
}
