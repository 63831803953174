.content {
  flex: 1 1 auto;
  .content__inner {
    margin-top: 150px;
    .content__direction {
        display: flex;
        flex-direction: column;
    }
  }
}
