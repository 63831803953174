.dialog {
  z-index: 100;
  width: 100%;
  .dialog__inner {
    border-radius: 15px;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 40%;
    height: 40%;
    min-width: 300px;
    min-height: 300px;
    background-color: white;
    transform: translate(-50%, -50%);
    .dialog__direction {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .header {
        // flex: 1 1 auto;
        .close__btn {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0);
          border: none;
          @media(min-width: "2048px"){
            font-size: 2em !important;
          }
          @media(min-width: "3840px"){
            font-size: 2.5em !important;
          }
        }
        .dialog__mainText {
          font-family: "Courier New", Courier, monospace;
          text-align: center;
          margin-top: 20px;
          padding: 0 15px;
          font-size: 24px;
          font-weight: bold;
          @media(min-width: "3840px"){
            font-size: 3em !important;
          }
        }
      }
    }
  }
}
