.display {
  width: 100%;
}
.display__inner {
    height: 100%;
    background-color: white;
    border: 2px solid black;
    .display__direction {
      code{
        display: block;
        font-family: 'Courier New', Courier, monospace;
      }
    }
  }