.check {
  .check__inner {
    .check__direction {
      display: flex;
      flex-direction: row;
      align-items: center;
      .check__text {
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 5px;
        font-size: 1.25rem;
        text-transform: uppercase;
        @media(min-width: "3840px"){
          font-size: 2.5em !important;
        }
      }
      input[type="checkbox"] {
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;

        margin: 0;
      }
      .check__check:checked {
        background: url("../../../../../../public/check.png");
        background-position: center;
        background-size: 20px;
        @media(min-width: "3840px"){
          background-size: 30px;
        }
        background-repeat: no-repeat;
      }
      .check__check:disabled {
        background: gray !important;
      }
      .check__check {
        position: relative;
        border-radius: 2px;
        border: 4px solid black;
        min-width: 35px;
        min-height: 35px;
        @media(min-width: "3840px"){
          min-width: 50px;
          min-height: 50px;
        }
      }
    }
  }
}
